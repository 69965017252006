@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');

@font-face {
  font-family: 'Roboto Slab', serif;
  src: local('Roboto Slab', serif), url(../fonts/Roboto-Light.woff) format('woff');
}
@font-face {
  font-family: 'ProximaNova';
  src: local('ProximaNova'), url(../fonts/ProximaNova-Regular.woff) format('woff');
}
body {
  height: 100% !important;
  font-family: 'Roboto Slab', serif !important;
  margin: 0  !important;
  background-color: #eaeef2;
  ::-webkit-scrollbar {
    display: none;
  }
}

// main {
//   display: block;
//   height: 100%;
// }



